import axios from 'axios'
import showToast from '../utils';
import Cookies from 'js-cookie'

let token = Cookies.get('SessionID')
// let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjp7IiRvaWQiOiI2NzMyMjA3NDAyMTEyNTAwMGJjZWE2ZjEifSwiZXhwIjoxNzQyNTA4Mzg0fQ.wVFCp5bgJQBgOr0pvIsPXIo3PYRs4VEo6IN_rmh0I5Q"
const httpConfig = {
  // baseURL: process.env.REACT_APP_PROJECTS_API,
  baseURL: process.env.REACT_APP_PROJECTS_API,
  // withCredentials: true,s
  headers:{
    'Content-Type':'application/json',
                'Acess-Control-Allow-Origin':'*',
                'Authorization':`Bearer ${token}`,
                'Accept': "application/json"
  }
};
// const httpConfig = {
//   baseURL: "https://analysis-backend-dot-data-science-siatik.ew.r.appspot.com"
// };
const httpClient = axios.create(httpConfig)
httpClient.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      showToast("Something went wrong, Please try again later", "error");
    } else {
      if (error.response.status === 403) {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, "error");
        }
      }
      if (error.response.status === 400) {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, "error");
        }
      }
      if (error.response.status === 404) {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, "error");
        }
      }
    }

    return Promise.reject(error);
  }
);

export default httpClient;
