import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Select from 'react-select';
import axios from 'axios';
import { fileDownload } from "../../../../actions/projects";

import IGVConifg from "./igvConfig"; // Adjust the import path according to your file structure

function IGVBrowser({pipeline_info}) {
  const dispatch = useDispatch();
  // Initialize with the first sample selected
  const [selectedSamples, setSelectedSamples] = useState(() => {
    if (pipeline_info.sample_names.length > 0) {
      return [{
        value: pipeline_info.sample_names[0],
        label: pipeline_info.sample_names[0]
      }];
    }
    return [];
  });
  const [tracks, setTracks] = useState([]);

  // Add useEffect to create initial track for the default selected sample
  useEffect(() => {
    const initializeDefaultTrack = async () => {
      if (selectedSamples.length > 0) {
        const initialTrack = await createTrack(selectedSamples[0]);
        setTracks([initialTrack]);
      }
    };
    
    initializeDefaultTrack();
  }, []); // Run only once on component mount

  let sample_names = pipeline_info.sample_names
  console.log(sample_names)

  // Convert sample names to react-select options format
  const sampleOptions = sample_names.map(sample => ({
    value: sample,
    label: sample
  }));
  console.log(pipeline_info)
  let run_id = pipeline_info.run_id
  let project_id = pipeline_info.project_id
  let user_id = pipeline_info.user_id
  let genome = pipeline_info.nfdetails.pipelines[0].params.find(param => param.genome).genome;
  // Create a single track for a sample
  const createTrack = async (sample) => {

    // Download the BAM file
    const bamResponse = dispatch(fileDownload({
      bucketName: `skygenic-user-${user_id}-standard-1`,
      fileName: `analysis-runs/${project_id}/${run_id}/outputs/rnaseq/star_salmon/${sample.value}.sorted.bam`,
    }));

    // const bamBlob = new Blob([bamResponse.data], { type: 'application/octet-stream' });
    const bamURL = bamResponse.downloadUrl

    // Download the BAM index file
    const baiResponse = dispatch(fileDownload({
      bucketName: `skygenic-user-${user_id}-standard-1`,
      fileName: `analysis-runs/${project_id}/${run_id}/outputs/rnaseq/star_salmon/${sample.value}.sorted.bam.bai`,
    }));

    // const baiBlob = new Blob([baiResponse.data], { type: 'application/octet-stream' });
    const baiURL = baiResponse.downloadUrl

    console.log(bamURL, baiURL)

    return {
      name: sample.value,
      url: bamURL,
      indexURL: baiURL,
      format: "bam",
      type: "alignment",
    };
  };

  // Handle sample selection changes
  const handleSampleChange = async (newSelectedSamples) => {
    // Handle clearing all selections
    if (!newSelectedSamples) {
      setSelectedSamples([]);
      setTracks([]);
      return;
    }

    // Get the current selection values
    const newSelectedValues = newSelectedSamples.map(sample => sample.value);
    
    // Remove tracks for deselected samples
    const updatedTracks = tracks.filter(track => 
      newSelectedValues.includes(track.name)
    );

    // Find newly added samples
    const addedSamples = newSelectedSamples.filter(
      sample => !selectedSamples.find(s => s.value === sample.value)
    );

    // Create and add new tracks for added samples
    for (const sample of addedSamples) {
      const newTrack = await createTrack(sample);
      updatedTracks.push(newTrack);
    }

    setSelectedSamples(newSelectedSamples);
    setTracks(updatedTracks);
  };


  const igvOptions = {
    genome: genome,
  };

  return (
    <div style={{
      width: "100%",
      height: "90vh",
      overflow: "auto",
    }}>
      <Select
        isMulti
        options={sampleOptions}
        value={selectedSamples}
        onChange={handleSampleChange}
        placeholder="Select samples..."
        className="mb-4"
      />
      {tracks.map((track, index) => (
        <div key={index} className="mb-4">
          <h3 >{track.name}</h3>
          <IGVConifg options={igvOptions} />
        </div>
      ))}
    </div>
  );
}

export default IGVBrowser;
