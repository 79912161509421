import React, { useState, useEffect } from "react";
import axios from "axios";
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const Parameters = ({ pipeline_info }) => {
  const [inputsData, setInputsData] = useState(null);
  const [paramsTab, setParamsTab] = useState("outputs");

  console.log(pipeline_info);



  return (
    <div
      className="div-block-26 params"
      style={{
        height: "650px",
      }}
    >
      <div className="div-block-46">
        <div
          className="text-block-20"
          // onClick={startAnalysis}
        >
          Analysis
        </div>
        {/* <div className="text-block-20">Star</div> */}
        <div className="text-block-20">Version 1.0</div>
      </div>
      <div className="div-block-40">
        <div
          className="div-block-41"
          style={{
            backgroundColor: paramsTab === "inputs" ? "#c5c5c5" : "",
          }}
          onClick={() => setParamsTab("inputs")}
        >
          Inputs
        </div>
        <div
          className="div-block-41"
          style={{
            backgroundColor: paramsTab === "settings" ? "#c5c5c5" : "",
          }}
          onClick={() => setParamsTab("settings")}
        >
          Settings
        </div>
        <div
          className="div-block-41"
          style={{
            backgroundColor: paramsTab === "outputs" ? "#c5c5c5" : "",
          }}
          onClick={() => setParamsTab("outputs")}
        >
          Outputs
        </div>
      </div>
      <div
        className="div-block-42"
        style={{
          overflow: "auto",
          maxHeight: "300px",
          display: paramsTab === "inputs" ? "flex" : "none",

          overflowWrap: "anywhere",
        }}
      >
        {/* {pipeline_info.pipelineDetails?.map((pd, i) => {
          return (
            <div
              onClick={() => {
                onDownload(output);
              }}
            >
              {output.split("/").pop()}
            </div>
          );
        })} */}
        {/* {JSON.stringify(inputsData)} */}
      <JsonView data={pipeline_info?.nfdetails} shouldExpandNode={allExpanded} style={defaultStyles} />

      </div>
      <div
        className="div-block-42"
        style={{
          overflow: "auto",
          maxHeight: "300px",
          display: paramsTab === "settings" ? "flex" : "none",
        }}
      ></div>
      <div
        className="div-block-42"
        style={{
          overflow: "auto",
          maxHeight: "600px",
          display: paramsTab === "outputs" ? "flex" : "none",
          flexDirection: "column"
        }}
      >
      <JsonView data={pipeline_info.nfdetails} shouldExpandNode={allExpanded} style={defaultStyles} />
        
      </div>
    </div>
  );
};

export default Parameters;


