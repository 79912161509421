import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../loader/Loader.component";
import { fileDownload } from "../../../actions/projects";
import { useDispatch } from "react-redux";

const AutoGenReport = ({ pipeline_info }) => {
  const [iframeUrls, setIframeUrls] = useState({});  // Store URLs for all reports
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const reports = pipeline_info?.ouputs?.["auto-gen-reports"] || [];
  const [selectedReport, setSelectedReport] = useState(reports[0] || null);
  
  console.log(pipeline_info)

  useEffect(() => {
    const fetchAllContent = async () => {
      try {
        const urls = {};
        for (const report of reports) {
          const bucketName = `gs://skygenic-user-${pipeline_info.created_user}-standard-1`;
          const fileName = `${pipeline_info.nfdetails.outdir}/${report.path}`.split(
            bucketName + "/"
          )[1];

          const response = await dispatch(fileDownload({ bucketName, fileName }));
          console.log("response", response);
          const htmlResponse = await axios.get(response.downloadUrl, {
            responseType: "blob",
          });

          const blob = new Blob([htmlResponse.data], { type: 'text/html' });
          const url = URL.createObjectURL(blob);
          urls[report.name] = url;
        }
        setIframeUrls(urls);
      } 
      catch (err) {
        console.error(err);
        setError("Failed to load the reports.");
      } 
      finally {
        setLoading(false);
      }
    };

    fetchAllContent();

    // Cleanup all Blob URLs when component unmounts
    return () => {
      Object.values(iframeUrls).forEach(url => {
        URL.revokeObjectURL(url);
      });
    };
  }, [dispatch, reports, pipeline_info]);

  const handleDownload = async () => {
    if (!selectedReport) return;
    
    try {
      const bucketName = `gs://skygenic-user-${pipeline_info.created_user}-standard-1`;
      const fileName = `${pipeline_info.nfdetails.outdir}/${selectedReport.path}`.split(
        bucketName + "/"
      )[1];

      const response = await dispatch(fileDownload({ bucketName, fileName }));

      // Create a temporary link element to trigger the download
      const link = document.createElement('a');
      link.href = response.downloadUrl;
      link.download = selectedReport.name + '.html';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
      setError("Failed to download the report.");
    }
  };

  if (error) {
    return <div className="div-block-26" style={{ height: "1000px" }}>{error}</div>;
  }

  return (
    <div className="div-block-26" style={{ height: "1000px" }}>
      <div style={{ 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center",
        marginBottom: "10px",
        width: "100%"
      }}>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
          {reports.map((report, index) => {
            const isSelected = selectedReport && selectedReport.name === report.name;
            return (
              <div
                key={index}
                className={`btn-2 type-2 ${isSelected ? 'selected' : ''}`}
                onClick={() => setSelectedReport(report)}
                style={{
                  cursor: "pointer",
                  backgroundColor: isSelected ? "#007bff" : "#f8f9fa",
                  color: isSelected ? "#fff" : "#000",
                  border: isSelected ? "1px solid #007bff" : "1px solid #ced4da",
                  borderRadius: "4px"
                }}
              >
                {report.name}
              </div>
            );
          })}
        </div>
        <button
          className="btn-2 type-2"
          onClick={handleDownload}
          disabled={!selectedReport}
          style={{
            cursor: selectedReport ? "pointer" : "not-allowed",
            backgroundColor: "#f8f9fa",
            border: "1px solid #ced4da",
            borderRadius: "4px",
            padding: "5px 15px"
          }}
        >
          Download
        </button>
      </div>
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        reports.map((report, index) => (
          <div
            key={index}
            style={{
              display: selectedReport?.name === report.name ? 'block' : 'none',
              height: '100%',
              width: '100%'
            }}
          >
            {iframeUrls[report.name] && (
              <iframe
                src={iframeUrls[report.name]}
                width="100%"
                height="100%"
                style={{ border: "none" }}
                title={`Report - ${report.name}`}
              >
                <p>
                  Your browser does not support iframes. You can view the report{" "}
                  <a href={iframeUrls[report.name]}>here</a>.
                </p>
              </iframe>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default AutoGenReport;
