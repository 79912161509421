import { useState } from "react";

const RightGuideSlideout = ({ guideContent }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="right-support" onClick={() => setShow(!show)}>
        <div className="text-block-61">
          G
          <br />
          U
          <br />
          I
          <br />
          D
          <br />
          E
        </div>
      </div>
      <div
        className="right-support-col"
      style={{
        display: show ? "flex" : "none",
      }}
      onClick={() => setShow(!show)}
    >
      {/* <div className="rs-top">
        <div className="text-block-62">Guided Support</div>
      </div>
      <div className="rs-line"></div> */}
        <div className="rs-content">
        <div 
      dangerouslySetInnerHTML={{ __html: guideContent }} 
      className="guide-content"
    />
        </div>
      </div>
    </>
  );
};

export default RightGuideSlideout;
