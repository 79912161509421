import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import ExpDesignModal from "../ExpDesignModal";
import DataTable from "react-data-table-component";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import axios from "axios";
import DriveModal from "./driveModal";
const ExperimentalDesign = ({
  plf,
  inputFileOut,
  setExpDesign,
  setExpDesignOut,
  workflow,
}) => {
  const [openExpDesignModal, setOpenExpDesignModal] = useState(false);
  const [metaDataFile, setMetaDataFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [expGenData, setExpGenData] = useState("");
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [edCols, setEdCols] = useState([]);
  const [edData, setEdData] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("csv");

  useEffect(() => {
    setFiles(
      plf
        ? plf.filter(
            (file) =>
              file.file_name.includes(".txt") || file.file_name.includes(".tsv")
            // ||
            // /\.fa(?!stq)/.test(file.file_name)
          )
        : []
    );

    console.log(
      "plf--",
      plf
        ? plf.filter(
            (file) =>
              file.file_name.includes(".txt") ||
              file.file_name.includes(".tsv") ||
              /\.fa(?!stq)/.test(file.file_name)
          )
        : []
    );
  }, [plf]);

  function round(num, decimalPlaces = 0) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
  }


  

  const inputColumns = useMemo(() => [
    {
      header: "Name",
      accessorKey: "file_name",
      sortable: true,
      format: ({ file_name, file_color }) => (
        <span
          className="file"
          style={{
            "--color": `#${file_color}`,
            "--hover-color": file_color === "ffffff" ? "" : `#${file_color}`,
            "--margin": file_color === "ffffff" ? "10px" : `5px`,
          }}
        >
          {file_name}
        </span>
      ),
    },
    // {
    //   name: "Exp Dsgn Tags",
    //   accessorKey: "tags",
    //   format: ({ tags }) => (
    //     <span style={{ margin: "5px" }}>
    //       {tags.map((tag, index) => (
    //         <span className="tag" key={index}>
    //           {tag}
    //         </span>
    //       ))}
    //     </span>
    //   ),
    //   wrap: true,
    // },
    {
      header: "Created",
      accessorKey: "date_created",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        <div>{moment.unix(renderedCellValue).format("MM/DD/YYYY")}</div>
      ),
    },
    {
      header: "Type",
      accessorKey: "file_type",
      sortable: true,
    },
    {
      header: "Size",
      accessorKey: "file_size",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => (
        // renderedCellValue is in bytes, convert to Bytes, MB & GB

        <div>
          {renderedCellValue === null
            ? ""
            : renderedCellValue < 1000
            ? `${renderedCellValue} Bytes`
            : renderedCellValue < 1000000
            ? `${round(renderedCellValue / 1000, 2)} KB`
            : renderedCellValue < 1000000000
            ? `${round(renderedCellValue / 1000000, 2)} MB`
            : `${round(renderedCellValue / 1000000000, 2)} GB`}
        </div>
      ),
    },
    {
      header: "Tags",
      accessorKey: "tags",
      format: ({ tags }) => (
        <span style={{ margin: "5px" }}>
          {tags.map((tag, index) => (
            <span className="tag" key={index}>
              {tag}
            </span>
          ))}
        </span>
      ),
      wrap: true,
    },
    {
      header: "Paired",
      accessorKey: "paired",
      sortable: true,
      hide: true,
    },
  ]);

  const tsvorcsv2arr = (tsv, delimiter) => {
    const [headers, ...rows] = tsv
      .trim()
      .split("\n")
      .map((r) => r.split(delimiter));
    return rows.reduce(
      (a, r) => [
        ...a,
        Object.assign(
          ...r.map((x, i, _, c = x ? x.trim() : "") => ({
            [headers[i].trim()]: isNaN(c) ? c : Number(c),
          }))
        ),
      ],
      []
    );
  };

  const handleCloseInputsModal = () => {
    setShowFilesModal(false);
    setFileLoading(true);
    console.log(rowSelection);
    let selectedRow = Object.keys(rowSelection);
    selectedRow = parseInt(selectedRow[0]);
    console.log(selectedRow);
    console.log(plf);
    const extExpFile = plf[selectedRow];

    if (!extExpFile) {
      return;
    }

    // setExpDesignOut(`gs://${files[selectedRow].bucket_id}/uploads/${files[selectedRow].imaginary_file_path}`);


    axios
      .post(
        "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/download",
        {
          bucketName: extExpFile.bucket_id,
          fileName: `uploads/${extExpFile.imaginary_file_path}`,
        }
      )
      .then((data) => {
        console.log(data.data);
        axios
          .get(data.data)
          .then((response) => {
            // setExpDesignOut(response.data);
            let delimiter = response.data.includes("\t") ? "\t" : ",";
            let g = response.data.split("\n").map((line) => line.split(delimiter));
      
            const ed_data = tsvorcsv2arr(response.data, delimiter);
            console.log(ed_data, "ed_data");
            setEdData(ed_data);
            setExpDesignOut(ed_data)
            let newCols = [];
            response.data
              .split("\n")[0]
              .split(delimiter)
              .map((cl) => {
                newCols.push({
                  name: cl,
                  selector: cl,
                  sortable: true,
                });
              });
            setEdCols(newCols);
            console.log(newCols, "newCols");
            setExpDesign(g[0].splice(1));
            // Split the TSV data into rows
            // console.log(response.data);
            // let g = response.data.split("\n").map((line) => line.split(","));
            // setExpDesign(g[0].splice(1));

            // const ed_data = tsv2arr(response.data);
            // setEdData(ed_data);
            // setEdCols(() => {
            //   let newCols = [];
            //   response.data
            //     .split("\n")[0]
            //     .split(",")
            //     .map((cl) => {
            //       newCols.push({
            //         name: cl,
            //         selector: cl,
            //         sortable: true,
            //       });
            //     });
            //   return newCols;
            // });

            setFileLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching the TSV data:", error);
          });
          console.log(data.data, "ed_data");
        // setEdCols(data.data);
      });
  };

  const uploadFile = async (file, user_bucket, filePath) => {
    console.log("Upload function", filePath, file);
    let inputformData = new FormData();
    inputformData.append("file", file);
    inputformData.append("bucketName", user_bucket);
    inputformData.append("filePath", filePath);
  
    var config = {
      method: "post",
      // url: "http://localhost:8080/upload",
      url: "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/upload",
      data: inputformData,
    };
  
    try {
      const response = await axios(config);
      console.log(response);
      console.log("File uploaded done", user_bucket, filePath, file);
    } catch (error) {
      console.log(error);
    }
  };

  const changeFileHandler = (_file) => {
    // let _file = event.target.files[0];
    // setMetaDataFile(_file);

    const reader = new FileReader();
    reader.onload = function (evt) {

      setExpDesignOut(evt.target.result)
      let delimiter = evt.target.result.includes("\t") ? "\t" : ",";
      let g = evt.target.result.split("\n").map((line) => line.split(delimiter));

      const ed_data = tsvorcsv2arr(evt.target.result, delimiter);
      console.log(ed_data, "ed_data");
      setEdData(ed_data);
      setExpDesignOut(ed_data)
      let newCols = [];
      evt.target.result
        .split("\n")[0]
        .split(delimiter)
        .map((cl) => {
          newCols.push({
            name: cl,
            selector: cl,
            sortable: true,
          });
        });
      setEdCols(newCols);
      console.log(newCols, "newCols");
      setExpDesign(g[0].splice(1));

    };
    reader.readAsText(_file);
  };

  let downloadFileSample = () => {
    console.log(workflow, "selectedWorkflow");
    let csv = workflow.steps.experimentalDesign.settings.sample_file_download
    let blob = new Blob([csv], { type: "text/csv" });
    let url = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = "sample-metadata.csv";
    a.click();
  }
  // useEffect(() => {
  //   if (expGenData.length > 0) {
  //     console.log(expGenData, "expGenData");
  //     var myblob = new Blob([expGenData], {
  //       type: "text/tab-separated-values",
  //     });
  //     changeFileHandler(myblob);
  //   }
  // }, [expGenData]);

  return (
    <>
      <div className="analysis-section-name" style={{
        marginTop: "30px",
        width: "80%",
      }}>For the required analysis, the scientist must generate a .csv or .txt file describing the experiment design. Download the sample file containing column headers that specify the required inputs. Populate the file with details for all input samples. Once completed, upload the file, and Skygenic will automatically validate it for errors.</div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <div className="text-block-42" onClick={downloadFileSample}>
          Download File Sample
        </div>
        {/* <div class="div-block-102" onClick={() => setShowInfoModal(true)}>
          i
        </div> */}
      </div>
      <div className="div-block-95-copy">
        <div
          className="div-block-96"
          style={{
            display: "flex",
          }}
        >
          <div
            className="text-block-52"
            style={{
              display: "block",
            }}
          >
            Select metadata file
          </div>
          <input
            type="file"
            className="btn-1 type-2"
            style={{
              marginRight: "10px",
              position: 'relative',
            }}
            accept=".tsv,.csv"
            onChange={(event) => changeFileHandler(event.target.files[0])}
            placeholder="Upload File"
            id="file-upload"
            hidden
          />
          <label 
            htmlFor="file-upload" 
            className="btn-1 type-2"
            style={{
              cursor: 'pointer',
              marginRight: '10px',
              marginBottom: '0px',
            }}
          >
            <div className="btn-1-text" style={{ fontSize: '14px' }}>
              Upload File
            </div>
          </label>

          <div
            // className="text-block-53"
            className="btn-1 type-2"
            onClick={() => {
              // setShowDriveModal(true);
              // setActiveChoosenInput(otherSetting);
              // setGlobalFilter(otherSetting.extension[0]);
            }}
          >
            <div
              className="btn-1-text"
              style={{
                fontSize: "14px",
              }}
              onClick={() => setShowFilesModal(true)}
            >
              Choose From <br />
              Storage
            </div>
          </div>
        </div>
      </div>
      
      {/* <input
        // className="text-block-42"
        className="btn-1 type-2"
        type="file"
        name="file"
        accept=".tsv"
        onChange={(event) => changeFileHandler(event.target.files[0])}
        placeholder="Upload Metadata.tsv"
        style={{
          marginBottom: "20px",
          marginTop: "20px",
          minHeight: "45px",
          paddingTop: "12px",
        }}
      /> */}
      {/* OR
      <div
        className="btn-1 type-2"
        style={{
          width: "300px",
          marginBottom: "30px",
          marginTop: "20px",
        }}
        onClick={() => {
          if (!inputFileOut) {
            alert("Please select some input files to continue.");
            return;
          }
          setOpenExpDesignModal(true);
        }}
      >
        Generate Experimental Design File
      </div> */}
      <div
        style={{
          width: "80%",
        }}
      >
        <DataTable
          columns={edCols}
          data={edData}
          defaultSortField="file_name"
          pagination
          highlightOnHover
          pointerOnHover
          onRowClicked={(x) => setOpenExpDesignModal(true)}
          progressPending={fileLoading}
          // progressPending={this.state.loadingFiles}
          // progressComponent={<Loader />
          // >}
        />
      </div>
      <ExpDesignModal
        inputFileOut={inputFileOut}
        open={openExpDesignModal}
        setOpenExpDesignModal={setOpenExpDesignModal}
        setExpGenData={setExpGenData}
        selectedPipeline={workflow}
      />
      <DriveModal
        show={showFilesModal}
        handleClose={handleCloseInputsModal}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        data={plf}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
      
      <Modal
        dialogClassName="modal-90w"
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
        keyboard={true}
        // fullscreen={true}
        centered
        size="lg"
        style={{
          overflowY: "auto",
        }}
      >
        <>
          <div
            className="div-datasample"
            style={{
              display: "flex",
            }}
          >
            <div>
              Provide a .tsv file during upload, here is a sample of it.
            </div>
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64f93435759c4491c92a9754_sampledata.png"
              loading="lazy"
              sizes="(max-width: 479px) 76vw, (max-width: 991px) 66vw, 636px"
              srcSet="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64f93435759c4491c92a9754_sampledata-p-500.png 500w, https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64f93435759c4491c92a9754_sampledata.png 636w"
              alt=""
            />
          </div>
        </>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowInfoModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExperimentalDesign;
