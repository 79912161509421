import React, { useEffect, useState, useMemo, memo } from "react";
import RightGuideSlideout from "./RightGuideSlideout";
import Select from "react-select";
import { DriveModal } from "./driveModal";

const StepSection = memo(({
  section,
  otherSettings,
  setShowDriveModal,
  setActiveChoosenInput,
  setOtherSettings,
  setOtherSettingsOut,
  otherSettingsOut,
  selectedStep,
}) => {
  const [handleRefSelect, setHandleRefSelect] = useState([]);
  const [sectionHidden, setSectionHidden] = useState(false);

  useEffect(() => {
    if (section.includes("[hidden]")) {
      setSectionHidden(true);
    }
  }, [section]);

  return (
    <>
      <>
        {section.startsWith("OR --") && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                flex: 1,
                height: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            ></div>
            <div style={{ margin: "0 10px", whiteSpace: "nowrap" }}>OR</div>
            <div
              style={{
                flex: 1,
                height: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            ></div>
          </div>
        )}
        {!sectionHidden ? (
          <div className="analysis-section-name">
            {section.replace("OR --", "").replace("[hidden]", "")}
          </div>
        ) : (
          <div
            className="hidden-element"
            onClick={() => setSectionHidden(!sectionHidden)}
          >
            <div class="text-block-52">
              {section.replace("OR --", "").replace("[hidden]", "")}
            </div>
            <img
              src="https://cdn.prod.website-files.com/60faa77e21b22054e04713b8/67ad676eebe02fb5865755c1_keyboard_arrow_down_30dp_666666_FILL0_wght400_GRAD-25_opsz24.svg"
              loading="lazy"
              alt=""
            ></img>
          </div>
        )}

        <div
          className="div-block-95-copy"
          style={{ display: sectionHidden ? "none" : "" }}
        >
          {otherSettings &&
            otherSettings.map((otherSetting, index) => {
              if (otherSetting.section === section) {
                let _options = [];
                if (Array.isArray(otherSetting.options)) {
                  _options = otherSetting.options;
                } else if (typeof otherSetting.options === "object") {
                  let _value = otherSettings.find(
                    (item) => item.name === otherSetting.options.parent
                  ).value;
                  otherSetting.options.options.forEach((item) => {
                    console.log(item);
                    if (item["parent-value"] === _value) {
                      _options = item.options;
                    }
                  });
                  console.log(_options);
                }
                return (
                  <div
                    key={`${otherSetting.name}-${index}`}
                    className="div-block-96"
                    style={{
                      display: "flex",
                    }}
                  >
                    {otherSetting.guide &&
                      (typeof otherSetting.guide === "object" ? (
                        (() => {
                          let _value = otherSettings.find(
                            (item) => item.name === otherSetting.guide.parent
                          ).value;
                          let guideContent;
                          otherSetting.guide.options.forEach((item) => {
                            if (item["parent-value"] === _value) {
                              guideContent = item.guide;
                            }
                          });
                          return (
                            <RightGuideSlideout guideContent={guideContent} />
                          );
                        })()
                      ) : (
                        <RightGuideSlideout guideContent={otherSetting.guide} />
                      ))}

                    <div
                      className="text-block-52"
                      style={{
                        display: "block",
                      }}
                    >
                      {otherSetting.name.charAt(0).toUpperCase() +
                        otherSetting.name.slice(1)}{" "}
                      - <br />
                      {(() => {
                        try {
                          return <b>{otherSetting?.value.split("/").pop()}</b>;
                        } catch (error) {
                          return <b>{otherSetting?.value}</b>;
                        }
                      })()}
                      {/* <b>{otherSetting?.value.split("/").pop()}</b> */}
                    </div>
                    {handleRefSelect.length > 0 ? (
                      <div
                        className="reference-dropdown"
                        onClick={() => setShowDriveModal(true)}
                      >
                        {handleRefSelect?.selectedRows[0]?.file_name}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="div-block-98">
                      <div
                        style={{
                          display:
                            otherSetting.interface_type === "dropdown"
                              ? "flex"
                              : "none",
                        }}
                      >
                        <Select
                          value={{
                            value: otherSetting.value
                              ? otherSetting.value
                              : _options[0]?.value,
                            label: otherSetting.value
                              ? otherSetting.value
                              : _options[0]?.label,
                          }}
                          onChange={(selectedOption) => {
                            const updatedOtherSettings = [...otherSettings];
                            updatedOtherSettings[index].value =
                              selectedOption.value;
                            updatedOtherSettings[index].file_doc = null;
                            setOtherSettings(updatedOtherSettings);
                            setOtherSettingsOut({
                              ...otherSettingsOut,
                              [selectedStep[0].name]: updatedOtherSettings,
                            });
                          }}
                          options={_options}
                          className={"reference-dropdown"}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              width: "220px",
                            }),
                            container: (baseStyles) => ({
                              ...baseStyles,
                              width: "220px",
                            }),
                          }}
                          isDisabled={
                            handleRefSelect.length === 0 ? false : true
                          }
                          placeholder="Reference Files"
                          menuPosition="fixed"
                          menuPlacement="auto"
                        />
                      </div>
                      <div
                        // className="text-block-53"
                        className="btn-1 type-2"
                        style={{
                          marginLeft: "14px",
                          display: otherSetting.interface_type.includes(
                            "drive_file_selector"
                          )
                            ? "flex"
                            : "none",
                        }}
                        onClick={() => {
                          setShowDriveModal(true);
                          setActiveChoosenInput(otherSetting);
                          // setGlobalFilter(otherSetting.extension[0]);
                        }}
                      >
                        <div
                          className="btn-1-text"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Choose From <br />
                          Storage
                        </div>
                      </div>
                      <div
                        // className="text-block-53"
                        className="btn-1 type-2"
                        style={{
                          marginLeft: "14px",
                          display: otherSetting.interface_type.includes(
                            "external_link"
                          )
                            ? "flex"
                            : "none",
                        }}
                        onClick={() => {
                          let _link;
                          let _value = otherSettings.find(
                            (item) => item.name === otherSetting.link.parent
                          ).value;
                          if (!_value) {
                            _value =
                              otherSetting.link.options[0]["parent-value"];
                          }
                          otherSetting.link.options.forEach((item) => {
                            if (item["parent-value"] === _value) {
                              _link = item.link;
                            }
                          });
                          window.open(_link, "_blank");
                        }}
                      >
                        <div
                          className="btn-1-text"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Open Link
                        </div>
                      </div>
                      <div
                        // className="text-block-53"
                        // className="btn-1 type-2"
                        style={{
                          marginLeft: "14px",
                          display: otherSetting.interface_type.includes(
                            "number-input"
                          )
                            ? "flex"
                            : "none",
                        }}
                      >
                        <input
                          type="number"
                          // className="reference-dropdown"
                          defaultValue={otherSetting.test_input}
                          style={{
                            width: "100px",
                            padding: "5px",
                            fontSize: "14px",
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Handle the change event as needed
                            console.log("Number input value:", value);
                            const updatedOtherSettings = [...otherSettings];
                            updatedOtherSettings[index].value = value;
                            setOtherSettings(updatedOtherSettings);
                            setOtherSettingsOut({
                              ...otherSettingsOut,
                              [selectedStep[0].name]: updatedOtherSettings,
                            });
                          }}
                        />

                        
                      </div>

                      <div
                        // className="text-block-53"
                        // className="btn-1 type-2"
                        style={{
                          marginLeft: "14px",
                          display: otherSetting.interface_type.includes(
                            "text-input"
                          )
                            ? "flex"
                            : "none",
                        }}
                      >
                        <input
                          type="text"
                          // className="reference-dropdown"
                          defaultValue={otherSetting.test_input}
                          style={{
                            width: "300px",
                            padding: "5px",
                            fontSize: "14px",
                          }}
                        />
                      </div>

                    </div>
                  </div>
                );
              }
            })}
        </div>
      </>
    </>
  );
});

export default StepSection;
