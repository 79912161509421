import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import DataTable from "react-data-table-component";
import Nav from "../../header/Nav.component";
import ResultsModal from "./resultsModal";
import { Modal, CloseButton } from "react-bootstrap";
import api from "../../../api/projects";
import { analysisDelete } from "../../../actions/projects";
import { toast, ToastContainer } from "react-toastify";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const Output = () => {
  const [allOutputs, setAllOutputs] = useState([]);
  const [pipeline_info, setPipeline_info] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAnalysisId, setDeleteAnalysisId] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  const getAnas = async () => {
    console.log("getAnas");
    const analysis_list = await api.get(`/analysis/${params.id}`);

    console.log(analysis_list.data);
    setAllOutputs(analysis_list.data);
  };

  useEffect(() => {
    getAnas();
  }, []);

  // run getAnas function very 30 secs
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getAnas();
  //   }, 30000);
  //   return () => clearInterval(interval);
  // }, []);

  const onDeleteProjectSubmit = async () => {
    setShowDeleteModal(false);
    dispatch(analysisDelete(deleteAnalysisId));
    getAnas();
    toast.success("Analysis Deleted");
    // const results = await api.get("/projects");
    // console.log(results);
    // dispatch({ type: "LIST_PROJECTS", payload: results.data });
  };

  const columns = [
    {
      header: "Analysis ID",
      accessorKey: "run_id",
      Cell: ({ renderedCellValue, row }) => (
        <div
          style={{
            marginLeft: "15px",
          }}
        >
          {renderedCellValue}
        </div>
      ),
    },
    {
      header: "Date Created",
      id: "date",
      accessorKey: "date",
      sortable: true,
      Cell: ({ renderedCellValue, row }) => {
        let curr_date = renderedCellValue?.created.toString().slice(0, -3);
        if (renderedCellValue) {
          return (
            <div>
              {moment.unix(curr_date).format("MM/DD/YYYY")},&nbsp; (
              {moment(moment.unix(curr_date)).fromNow()})
            </div>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      sortable: true,
      // if status is "Submitted" then show "Running"
      Cell: ({ renderedCellValue, row }) => (
        <span>
          {renderedCellValue === "Submitted" ? "Running" : renderedCellValue}
        </span>
      ),
    },
    {
      header: "Run Time",
      accessorKey: "runtime",
      Cell: ({ renderedCellValue, row }) => {
        function timeDiff(tstart, tend) {
          var diff = Math.floor((tend - tstart) / 1000),
            units = [
              { d: 60, l: "sec" },
              { d: 60, l: "mins" },
              { d: 24, l: "hrs" },
            ];

          var s = "";
          for (var i = 0; i < units.length; ++i) {
            s = (diff % units[i].d) + " " + units[i].l + " " + s;
            diff = Math.floor(diff / units[i].d);
          }
          return s;
        }
        // let timeTaken =  renderedCellValue - row?.original.created.toString().slice(0, -3)
        let timeTaken = timeDiff(
          row?.original.date?.created,
          row?.original.finish_time
        );
        return <span>{row?.original.finish_time ? timeTaken : ""}</span>;
      },
    },
    {
      header: "Delete",
      accessorKey: "_id",
      Cell: ({ renderedCellValue, row }) => (
        <CloseButton
          key={row.original._id}
          id={row.original._id}
          className={row.original._id}
          onClick={() => {
            setShowDeleteModal(true);
            setDeleteAnalysisId(row.original.run_id);
          }}
          style={{
            width: "42px",
            height: "24px",
            fontSize: "15px",
            borderRadius: "4px",
          }}
        />
      ),
      right: true,
    },
  ];

  const allProjects = useSelector((state) => state.projects.userProjects);
  const [openResultsModal, setOpenResultsModal] = useState(false);

  const table = useMaterialReactTable({
    // filter by date
    // data: allOutputs.sort((a, b) => b.date.created - a.date.createdx),
    data: allOutputs ? allOutputs : [],
    columns: columns,
    density: "compact",
    // enableColumnOrdering: true,
    // columnFilterDisplayMode: 'popover',
    enableColumnFilterModes: true,
    initialState: {
      // showColumnFilters: true,
      pagination: { pageSize: 15, pageIndex: 0 },
      density: "compact",
    },
    muiTableBodyRowProps: ({ row, jjj }) => ({
      onClick: (e) => {
        e.stopPropagation();
        console.log(row, jjj, row.original.status);

        setPipeline_info(row.original);
          setOpenResultsModal(true);

        // if (row.original.status === "Succeeded" || row.original.status === "completed" || row.original.status === "error") {
        //   setOpenResultsModal(true);
        //   setPipeline_info(row.original);
          
        // } else if (row.original.status === "Submitted") {

        //   console.log("runngin", )
        //   toast.info("Analysis is still running. Please wait for it to finish.");
        // } 
      },
      sx: { cursor: "pointer" },
    }),
  });


  return (
    <>
      <Nav active={4} />
      
      <div
        style={{
          padding: "6vh 8vw",
        }}
      >
        <button className="text-block-42" onClick={() => getAnas()}>
          Refresh
        </button>
        
  
        <MaterialReactTable
          table={table}
          initialState={{
            density: "compact",
            sorting: [
              {
                id: 'date', // The id of the column to sort
                desc: false, // Set to true for descending order, false for ascending order
              }
            ]
          }}
        />

        <br />
        <br />
        <br />

        <Modal
          show={openResultsModal}
          onHide={() => setOpenResultsModal(false)}
          // backdrop="static"
          keyboard={true}
          // fullscreen={true}
          centered
          // size="lg"
          // dialogClassName="div-block-12"
          // dialogClassName="modal-98w"
          // style={{
          //   width: "100vw",
          //   height: "100vh",
          // }}
          // contentClassName="div-block-12"
        >
          <ResultsModal
            setOpenResultsModal={setOpenResultsModal}
            pipeline_info={pipeline_info}
          />
        </Modal>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        style={{
          width: "50vw",
          transform: "translate(50%, 10%)",
        }}
      >
        <Modal.Header closeButton>
          <h4>Delete Analysis</h4>
        </Modal.Header>
        <form>
          <Modal.Body>
            <h5>
              Are you sure you want to delete this analysis & its output files.
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              type="submit"
              defaultValue="Delete"
              data-wait="Please wait..."
              onClick={() => onDeleteProjectSubmit()}
            >
              Delete Analysis
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Output;
