// analysisWizard.jsx
import React, { useState, useEffect } from "react";
import "./wizard.css";
import wizardConfig from "./wizardConfig.json";

const AnalysisWizard = () => {
  const [currentStep, setCurrentStep] = useState("1");
  const [path, setPath] = useState([]);
  const questions = wizardConfig;

  useEffect(() => {
    if (!questions[currentStep]) {
      console.error("Invalid step in wizard configuration.");
    }
  }, [currentStep, questions]);

  const handleSelection = (option) => {
    setPath([...path, option.label]);
    if (option.next) {
      setCurrentStep(option.next.toString());
    } else if (option.action) {
      alert(`Recommended workflow: ${option.action}`);
    }
  };

  if (!questions[currentStep]) return <p>Loading...</p>;

  return (
    <div className="wizard-container">
      <h2>{questions[currentStep].text}</h2>
      <div className="wizard-options">
        {questions[currentStep].options.map((option, index) => (
          <button 
            key={index} 
            className={`wizard-button ${option.next || option.action ? "active" : "disabled"}`} 
            onClick={() => handleSelection(option)}
            disabled={option.disabled || (!option.next && !option.action)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AnalysisWizard;
