import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Nav from "../../header/Nav.component";
import { Modal, Button } from "react-bootstrap";
import { listProjectFiles } from "../../../actions/projects";
import Strategy from "./Strategy";
import InputFiles from "./InputFiles";
import "../Analysis.css";
import ExperimentalDesign from "./ExperimentalDesign";
import Summary from "./Summary";
import { ToastContainer, toast } from "react-toastify";
import AnalysisWizard from "../analysisWizard/analysisWizard";
import startAnalysis from "./analysisRequest";
import workflows_data from "./workflows.json";
import rootWorkflows from "../../../nf-configs/rootworkflows.json";
import nfWorkflows from "../../../nf-configs/nfworkflows.json";
import ReferenceFile2 from "./ReferenceFile2";
import InputFile2 from "./InputFile2";
import OtherSettings from "./otherSettings";

const Analysis = ({ match }) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const projectFiles = useSelector((state) => state.projects.projectFiles);
  const currentUser = useSelector((state) => state.projects.currentUser);
  // get project id
  // const match = useParams();

  const [plf, setPfs] = useState(null);

  useEffect(() => {
    dispatch(listProjectFiles(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    let combineFilesList = [];
    projectFiles?.files.forEach((file) => {
      if (file.file_type.includes("fastq")) {
        let newFileName = file.file_name.split(".fastq")[0];

        if (newFileName.slice(-1) === "1") {
          newFileName += "2";
          combineFilesList.push({
            file_name: newFileName,
            imaginary_file_type: file.imaginary_file_type,
            tags: file.tags,
            file_tags: file.file_tags,
            file_color: file.file_color,
          });
        }
      }
    });
    setPfs(projectFiles?.files);
  }, [projectFiles]);

  useEffect(() => {
    dispatch(listProjectFiles);
  }, []);

  const [showAlignmentModal, setShowAlignmentModal] = useState(false);
  const [analysisStep, setAnalysisStep] = useState(0);
  const [expDesign, setExpDesign] = useState([]);
  const [steps, setSteps] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [inputFile2Out, setInputFile2Out] = useState(null);

  const [strategyOut, setStrategyOut] = useState(undefined);
  const [inputFileOut, setInputFileOut] = useState(undefined);
  const [referenceFileOut, setReferenceFileOut] = useState(undefined);
  const [expDesignOut, setExpDesignOut] = useState(undefined);
  const [comparisonSelectionOut, setComparisonSelectionOut] =
    useState(undefined);
  const [vmSpeed, setVmSpeed] = useState({
    value: "high",
    label: "High",
  });
  const [otherSettingsOut, setOtherSettingsOut] = useState(undefined);

  const [workflows, setWorkflows] = useState(workflows_data);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [selectedRootWorkflow, setSelectedRootWorkflow] = useState(null);

  const [selectedStep, setSelectedStep] = useState(null);

  const [analysisSteps, setAnalysisSteps] = useState([
    { name: "Alignment+quantification - Star", buttonName: "Configuration" },
    { name: "Differential Expression", buttonName: "Settings" },
  ]);



  const handleCloseAlignmentModal = () => {
    setShowAlignmentModal(false);
  };

  const handleSelectWorkflow = (rwf) => {
    console.log(rwf);
    setAnalysisStep(2);
    console.log(selectedWorkflow);
  };

  useEffect(() => {
    console.log("ggg1");
    console.log(selectedWorkflow);

    if (selectedWorkflow !== null) {
      // setSteps(selectedWorkflow.steps);
      console.log(selectedWorkflow.steps);
      setSelectedStep([Object.keys(selectedWorkflow.steps)[0], 0]);
    }
  }, [selectedWorkflow]);

  // -------------- START ANALYSIS --------------
  const runAnalysisRequest = async () => {
    history.push(`/${params.id}/output`);

    console.log(inputFileOut);
    console.log(inputFile2Out);
    console.log(referenceFileOut);
    console.log(expDesignOut);
    console.log(comparisonSelectionOut);
    console.log(vmSpeed);
    console.log(otherSettingsOut);

    startAnalysis(
      selectedWorkflow,
      inputFileOut,
      inputFile2Out,
      referenceFileOut,
      expDesignOut,
      comparisonSelectionOut,
      vmSpeed,
      otherSettingsOut,
      dispatch,
      currentUser,
      params,
      selectedPipeline
    );
    return;
  };

  // -----------------------------------------

  return (
    <>
      <Nav active={3} />
      <ToastContainer />
      <div
        className="div-block-107"
        style={{
          display: analysisStep === 0 ? "flex" : "none",
        }}
      >
        <div
          className="div-block-64"
          // onClick={() => {
          //   setAnalysisStep(1);
          //   // setSelectedRootWorkflow(g1);
          //   // setSelectedWorkflow(
          //   //   workflows.filter(
          //   //     (_wf) => _wf.name === g1.defaultLinkedWorkFlow
          //   //   )[0]
          //   // );
          // }}
        >
          <img
            src="https://assets-global.website-files.com/60faa77e21b22054e04713b8/61b86eff3613d1659d0acc31_icons8-workflow-96.png"
            loading="lazy"
            width={42}
            alt=""
          />
          <div className="text-block-22">Wizard Walkthrough</div>
          <div className="text-block-23">
            Automatically choosen relevant workflow, based on set of questions{" "}
          </div>
        </div>
      </div>

      <div
        className="div-workflow"
        style={{
          display: analysisStep === 0 ? "flex" : "none",
          height: "100vh",
        }}
      >
        <div className="div-block-65" />
        {/* {["RNA-Seq alignment", "RNA-Seq DB Build", "RNA-Seq Full"] */}
        {rootWorkflows.map((rwf, i) => {
          return (
            <div
              className="div-block-64"
              key={i}
              onClick={() => {
                if (!rwf.disabled) {
                  handleSelectWorkflow(rwf);
                  console.log(rwf);
                  const workflowDetails =
                    nfWorkflows.workflows[rwf.rootWorkFlow]; // Fetch the workflow details
                  console.log("workflowDetails", workflowDetails);
                  setSelectedWorkflow(workflowDetails); // Set the selected workflow
                }
              }}
              style={{
                cursor: rwf.disabled ? "not-allowed" : "",
              }}
            >
              <div
                class="overlay-soon"
                style={{
                  display: rwf.disabled ? "block" : "none",
                }}
              >
                Coming Soon
              </div>
              <img
                src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/61b86eff3613d1659d0acc31_icons8-workflow-96.png"
                loading="lazy"
                width={42}
                alt=""
              />
              <div className="text-block-22">{rwf.displayName}</div>
              <div className="text-block-23">{rwf.description}</div>
            </div>
          );
        })}
      </div>

      <div
        className="section-2 wf-section"
        style={{
          display: analysisStep === 1 ? "flex" : "none",
          alignItems: "center",
        }}
      >
        <AnalysisWizard
          setSelectedWorkflow={setSelectedWorkflow}
          setAnalysisStep={setAnalysisStep}
        />
      </div>

      <div
        className="section-2 wf-section"
        style={{
          display: analysisStep === 2 ? "flex" : "none",
        }}
      >
        {/* <div className="div-block-2">
          <div className="div-block-3">Overview</div>
          <div className="div-block-3 active">Project Summery</div>
          <div className="div-block-3">Files</div>
        </div> */}
        <div className="div-analysis-nav">
          <div className="div-block-24">
            <div className="text-block-4">{selectedWorkflow?.name}</div>
          </div>
          <div className="text-block-9">{/* Description.. */}</div>
          <div className="div-analysis-desc" />
        </div>

        <div className="div-main">
          {/* <div className="div-main"> */}
          <div className="div-block-87">
            <div className="div-block-90">
              <div className="ver-line" />
              {selectedWorkflow &&
                selectedStep &&
                Object.keys(selectedWorkflow.steps).map((step, i) => {
                  console.log(step);
                  return (
                    <div
                      className={`div-block-88 ${
                        selectedStep[1] === i || selectedStep[1] > i
                          ? "active"
                          : ""
                      }`}
                      key={i}
                      onClick={() => setSelectedStep([step, i])}
                    >
                      <div
                        className={`text-block-49 ${
                          selectedStep[1] === i ? "active" : ""
                        }`}
                      >
                        {selectedWorkflow.steps[step].name}
                      </div>
                      <div
                        className={`div-block-89 ${
                          selectedStep[1] === i ? "active" : ""
                        }`}
                      >
                        {i + 1}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {selectedStep && (
            <div className="div-block-91" style={{
              alignItems: "center",
            }}>
              <div
                className="analysis-statergy-block"
                style={{
                  display: selectedStep[0] === "strategy" ? "flex" : "none",
                }}
              >
                <Strategy
                  setStrategyOut={setStrategyOut}
                  workflow={selectedWorkflow}
                  setSelectedWorkflow={setSelectedWorkflow}
                  selectedRootWorkflow={selectedRootWorkflow}
                />
              </div>
              <div
                className="analysis-inputf-block"
                style={{
                  display: selectedStep[0] === "input" ? "flex" : "none",
                }}
              >
                {selectedWorkflow.steps.input.settings.interface_type.includes(
                  "sample_selection_table"
                ) && (
                  <InputFiles
                    plf={plf}
                    inputFileOut={inputFileOut}
                    setInputFileOut={setInputFileOut}
                    selectedRootWorkflow={selectedRootWorkflow}
                    workflow={selectedWorkflow}
                  />
                )}
                {selectedWorkflow.steps.input.settings.interface_type.includes(
                  "drive_file_selector"
                ) && (
                  <InputFile2
                    plf={plf}
                    setInputFile2Out={setInputFile2Out}
                    selectedRootWorkflow={selectedRootWorkflow}
                  />
                )}
              </div>
              <div
                className="analysis-inputf-block"
                style={{
                  display: selectedStep[0] === "reference" ? "flex" : "none",
                }}
              >
                <ReferenceFile2
                  plf={plf}
                  setReferenceFileOut={setReferenceFileOut}
                  workflow={selectedWorkflow}
                />
              </div>
              <div
                className="analysis-inputf-block"
                style={{
                  display:
                    selectedStep[0] === "experimentalDesign" ? "flex" : "none",
                  width: "80%",
                }}
              >
                <ExperimentalDesign
                  plf={plf}
                  inputFileOut={inputFileOut}
                  setExpDesign={setExpDesign}
                  setExpDesignOut={setExpDesignOut}
                  workflow={selectedWorkflow}
                />
              </div>
              <div
                className="analysis-inputf-block"
                style={{
                  display: selectedStep[0] === "comparison" ? "flex" : "none",
                }}
              >
                {/* <ComparisonSelection
                expDesign={expDesign}
                setComparisonSelectionOut={setComparisonSelectionOut}
                workflow={selectedWorkflow}
              /> */}
              </div>
              <div
                className="analysis-inputf-block"
                style={{
                  display: selectedStep[0].includes("other") ? "flex" : "none",
                }}
              >
                <OtherSettings
                  plf={plf}
                  _otherSettings={otherSettingsOut}
                  setOtherSettingsOut={setOtherSettingsOut}
                  workflow={selectedWorkflow}
                  selectedStep={selectedStep}
                />
              </div>
              <div
                className="analysis-summary-block"
                style={{
                  display: selectedStep[0] === "summary" ? "flex" : "none",
                }}
              >
                <Summary
                  workflow={selectedWorkflow}
                  comparisonSelectionOut={comparisonSelectionOut}
                  referenceFileOut={referenceFileOut}
                  inputFileOut={inputFileOut}
                  vmSpeed={vmSpeed}
                  setVmSpeed={setVmSpeed}
                  otherSettingsOut={otherSettingsOut}
                />
              </div>
              {selectedStep[0] !== "summary" ? (
                <div
                  className="div-block-93"
                  style={{
                    padding: "3%",
                  }}
                >
                  <div className="div-block-94">
                    <div
                      className="prev-next-btn"
                      onClick={() => {
                        setSelectedStep((x) => {
                          if (x[1] > 0) {
                            return [Object.keys(selectedWorkflow.steps)[x[1] - 1], x[1] - 1];
                          }
                        });
                      }}
                    >
                      Back
                    </div>
                    <div
                      className="prev-next-btn next"
                      onClick={() => {
                        setSelectedStep((x) => {
                          if (x[1] < Object.keys(selectedWorkflow.steps).length - 1) {
                            return [Object.keys(selectedWorkflow.steps)[x[1] + 1], x[1] + 1];
                          }
                        });
                      }}
                    >
                      Next
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="div-block-93"
                  style={{
                    padding: "3%",
                  }}
                >
                  <div className="div-block-94">
                    <div
                      className="analysis-start-btn"
                      onClick={runAnalysisRequest}
                    >
                      Start Analysis
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Experimental Design Modal */}

      {/* --- Alignment Modal --- */}
      <Modal
        show={showAlignmentModal}
        onHide={handleCloseAlignmentModal}
        // backdrop="static"
        keyboard={true}
        // fullscreen={true}
        centered
        // size="lg"
        dialogClassName="div-block-12"
        // contentClassName="div-block-12"
      >
        {/* <Modal.Body> */}
        {/* <div className="div-block-12" style={{
          width: "100%"
        }}> */}
        <div className="div-block-13">
          <div className="div-block-25">
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc3a2f21621b84f7932381_icons8-back-96.png"
              loading="lazy"
              width={16}
              alt=""
            />
            <div className="text-block-3">Back</div>
          </div>
          <div className="text-block-2">Alignment</div>
          <div />
        </div>
        <div className="div-block-27">
          <div className="div-block-39">Star</div>
          <div className="div-block-39">Bowtie2</div>
          <div className="div-block-39">Burrows-Wheeler Aligner (BWA)</div>
          <div className="div-block-39">HiSat2</div>
          <div className="div-block-39">Tophat</div>
        </div>
        <div className="div-block-29">
          <div className="div-block-30">
            <div className="div-block-43">
              <div className="text-block-18">Star:</div>
              <div className="btn-2">
                <div className="btn-2-text">Version 2.0</div>
                <img
                  src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc2f152b2dc0e3c9a69611_icons8-chevron-down-90.png"
                  loading="lazy"
                  width={22}
                  alt=""
                  className="image"
                />
              </div>
            </div>
            <div className="text-block-17">Application:</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
            <div className="text-block-17">Use Case:</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
            <div className="text-block-17">Input Requirments</div>
            <p className="text-block-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </p>
          </div>
          <div className="div-block-31">
            <div className="text-block-17 center">App Information</div>
            <p className="text-block-8 center">
              <a href="#" className="link">
                Homepage
              </a>
              <br />
              <br />
              <a href="#" className="link">
                Publication
                <br />
              </a>
              <br />
              <a href="#" className="link">
                Source Code
              </a>
              <br />
              <br />
              <a href="#" className="link">
                License
              </a>
            </p>
          </div>
        </div>
        <div className="div-block-26">
          <div className="div-block-40">
            <div className="div-block-41">Inputs</div>
            <div className="div-block-41">Settings</div>
            <div className="div-block-41">Outputs</div>
          </div>
          <div className="div-block-42" />
        </div>
        {/* </div>/ */}
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
};

export default Analysis;
