import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";
import moment from "moment";
import { MaterialReactTable } from "material-react-table";
import DriveModal from "./driveModal";
import RightGuideSlideout from "./RightGuideSlideout";
import StepSection from "./stepSection";

const OtherSettings = ({
  plf,
  _otherSettings,
  setOtherSettingsOut,
  workflow,
  selectedStep,
}) => {
  const [otherSettings, setOtherSettings] = useState([]);
  const [showDriveModal, setShowDriveModal] = useState(false);
  const [activeChoosenInput, setActiveChoosenInput] = useState();

  const [rowSelection, setRowSelection] = useState([]);

  useEffect(() => {
    if (selectedStep[0].includes("others")) {
      if(!_otherSettings || !_otherSettings.hasOwnProperty(selectedStep[0])){
        let new_otherSettings = [];
        console.log(selectedStep);
        workflow?.steps[selectedStep[0]]?.settings.forEach((item) => {
          item.value = item.test_input;
          console.log(item);
          new_otherSettings.push(item);
        });

        setOtherSettings(new_otherSettings);
        setOtherSettingsOut({..._otherSettings, [selectedStep[0]]: new_otherSettings});
      } else {
        setOtherSettings(_otherSettings[selectedStep[0]]);
      }

    }
    console.log(_otherSettings);
  }, [workflow, selectedStep]);

  useEffect(() => {
    // Row selection
    console.log(rowSelection);
    try {
      if(otherSettings.length > 0){
      let selectedRow = Object.keys(rowSelection);
      selectedRow = parseInt(selectedRow[0]);

      let x = plf[selectedRow];
      if (!x) return;
      let _updated = otherSettings.map((item) => {
        if (item.name === activeChoosenInput.name) {
          return { ...item, value: `gs://${x.bucket_id}/uploads/${x.imaginary_file_path}`, file_doc: x };
        }
        return item;
      });
      setOtherSettings(_updated)
      setOtherSettingsOut(x => ({...x, [selectedStep[0]]: _updated}));
      }
    } catch (error) {
      console.log(error);
    }
  }, [rowSelection]);

  return (
    <>
      <div
        className="analysis-reference-block"
        style={{
          display: "flex",
        }}
      >
        <div className="text-block-50">
          {selectedStep[0].includes("others") &&
            workflow?.steps[selectedStep[0]].description}
        </div>
        {/* <div className="text-block-50">{"stepInfo"}</div> */}
        <div className="text-block-50"></div>
        {selectedStep[0].includes("others") &&
          workflow?.steps[selectedStep[0]]?.sections.map((section) => {
            return (
              <StepSection
                section={section}
                otherSettings={otherSettings}
                setShowDriveModal={setShowDriveModal}
                setActiveChoosenInput={setActiveChoosenInput}
                setOtherSettings={setOtherSettings}
                setOtherSettingsOut={setOtherSettingsOut}
                otherSettingsOut={_otherSettings}
                selectedStep={selectedStep}
              />
            );
          })}
      </div>
      <DriveModal
        show={showDriveModal}
        handleClose={() => setShowDriveModal(false)}
        data={plf}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </>
  );
};

export default OtherSettings;
